<template>
  <div class="container margin">
    <side-nav>
      <div class="train">
        <div class="train__filter">
          <div class="flex flex__space-between">
            <div class="train__filter-item flex flex__align-center flex__end">
              <div class="train__filter-text">姓名：</div>
              <a-input v-model="formInline.userRealname" allowClear style="width:280px" placeholder="请输入" />
            </div>
            <div class="train__filter-item flex flex__align-center flex__end">
              <div class="train__filter-text">部门：</div>
              <!-- <a-select
                v-model="formInline.departId"
                style="width:280px"
                placeholder="请选择"
                allowClear
              >
                <a-select-option
                  :value="item.key"
                  v-for="(item) in departData"
                  :key="item.key"
                >{{item.title}}</a-select-option>
              </a-select>-->
              <a-tree-select style="width:280px" :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }"
                :treeData="departTree" v-model="formInline.departId" placeholder="请选择部门" allowClear></a-tree-select>
            </div>
          </div>
          <div class="flex flex__space-between">
            <div class="train__filter-item flex flex__align-center flex__end">
              <div class="train__filter-text">学习任务：</div>
              <a-select v-model="formInline.taskId" style="width:280px" placeholder="请选择" allowClear>
                <a-select-option :value="item.taskId" v-for="(item, index) in taskData" :key="index">{{ item.taskName }}
                </a-select-option>
              </a-select>
            </div>
            <div class="train__filter-item flex flex__align-center flex__end">
              <div class="train__filter-text">到期时间：</div>
              <a-date-picker @change="onChange" style="width:280px" />
            </div>
          </div>
          <div class="text__center">
            <a-button type="primary" shape="round" class="text__center_btn"
              style="background: #C4A468;margin-right:15px;" html-type="submit" @click="search">查询</a-button>
            <a-button shape="round" class="text__center_btn" style="background: #B1B1B1;" html-type="submit"
              @click="resetForm">重置</a-button>
          </div>
        </div>
        <div class="train__line" />
        <div class="train__table">
          <a-table :columns="columns" :pagination="false" :data-source="data" :rowKey="(record, index) => index"
            size="middle" :loading="loading" :scroll="{ y: 345 }">
            <template slot="number" slot-scope="text, record, indent">
              <span>{{ indent + 1 }}</span>
            </template>
            <template slot="taskCompleteRatio" slot-scope="text">
              <span>{{ Number(text * 100).toFixed('0') }}%</span>
            </template>
            <template slot="taskExpireStatus" slot-scope="text,record">
              <!-- <span v-if="text === 'EXPIRE'">未到期</span>
              <span v-else-if="text === 'WILL_EXPIRE'">临期</span>-->
              <span v-if="record.taskCompleteRatio === 1">已完成</span>
              <span v-else>未完成</span>
            </template>
            <template slot="action" slot-scope="text, record">
              <a @click="toDetail(record)">查看详情</a>
            </template>
          </a-table>
        </div>
        <div class="train__charts">
          <div class="train__charts-tip">下级共 {{ chartData.length ? chartData.length : '0' }} 人，平均完成率：{{ complete }}%
          </div>
          <div id="charts"></div>
          <div class="text__center" style="margin-top:10px">x : 闯关进度 , y : 员工姓名</div>
        </div>
      </div>
    </side-nav>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
// 引入条形状图图表，图表后缀都为 Chart
import { BarChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from "echarts/components";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import { getTrainData } from "@/api/news";
import { getDepartment } from "@/api/user";
import { queryTreeList } from "@/api/home";
const columns = [
  {
    title: "序号",
    dataIndex: "number",
    align: "center",
    scopedSlots: { customRender: "number" }
  },
  {
    title: "姓名",
    dataIndex: "userRealname",
    align: "center"
  },
  {
    title: "上级部门",
    dataIndex: "departNameAbbr",
    align: "center"
  },
  {
    title: "部门",
    dataIndex: "departName",
    align: "center"
  },
  {
    title: "学习任务",
    dataIndex: "taskName",
    align: "center"
  },
  {
    title: "完成率",
    dataIndex: "taskCompleteRatio",
    align: "center",
    scopedSlots: { customRender: "taskCompleteRatio" }
  },
  {
    title: "到期时间",
    dataIndex: "endTime",
    align: "center"
  },
  {
    title: "状态",
    dataIndex: "taskExpireStatus",
    align: "center",
    scopedSlots: { customRender: "taskExpireStatus" }
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" }
  }
];

import sideNav from "@/components/side-nav";
export default {
  components: { sideNav },
  data() {
    return {
      formInline: {},
      taskData: [],
      departData: [],
      departTree: [],
      columns,
      data: [],
      stat: {},
      myChart: null,
      loading: false,
      statComp: [],
      chartData: [],
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 1],
          min: 0,
          max: 100,
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value} %"
          }
          // show: true
        },
        yAxis: {
          type: "category",
          data: []
        },
        series: [
          {
            type: "bar",
            data: [],
            barWidth: 30,
            label: {
              show: true, //开启显示
              position: "right", //在上方显示
              textStyle: {
                //数值样式
                color: "#666666",
                fontSize: 12
              }
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#FF9D73' },
                { offset: 1, color: '#FE7090' }
              ])
            },
          }
        ]
      }
    };
  },
  computed: {
    complete() {
      if (this.stat.avgCompleteRatio) {
        let num = this.stat.avgCompleteRatio * 100;
        return Number(num).toFixed("0");
      } else {
        return 0;
      }
    }
  },
  created() {
    this.getData();
    this.getTask();
    this.getDepart();
    this.getDepartTree();
    // for(let i = 0; i < 50; i++){
    //   this.taskData.push({
    //     taskId: i,
    //     taskName: i
    //   })
    // }
  },
  mounted() {
    // 注册必须的组件
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      LegendComponent,
      BarChart,
      CanvasRenderer
    ]);
    // 绑定一个要渲染图形的dom元素
    this.myChart = echarts.init(document.getElementById("charts"));
    // 设置图形配置项
    this.myChart.setOption(this.option);
  },
  methods: {
    getData() {
      this.loading = true;
      getTrainData(this.formInline)
        .then(res => {
          if (res.success === false) {
            this.$message.warning("只能查看本部门数据");
          }
          if (!res.result) {
            this.data = [];
            this.stat = [];
            return false;
          }
          console.log(res.result.stat.avgCompleteRatio, "avgCompleteRatio");
          this.data = res.result.list;
          this.stat = res.result.stat;
          this.renderChart();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //获取学习任务
    getTask() {
      this.loading = true;
      getTrainData(this.formInline).then(res => {
        this.taskData = [];
        console.log(res.result, "res");
        if (res.result != null) {
          res.result.taskList.forEach(item => {
            this.taskData.push(item);
          });
          this.data = res.result.list;
          this.statComp = res.result.stat;
        }
      });
    },
    // 获取部门数据
    getDepart() {
      getDepartment().then(res => {
        this.departData = res.result;
      });
    },
    getDepartTree() {
      queryTreeList().then(res => {
        console.log(res.result, "res");
        this.departTree = res.result;
      });
    },
    // 处理数据
    setData(data) {
      let arr = [];
      data.forEach(item => {
        var index = 0; // arr数组的索引, 默认为0
        let isFand = arr.some((ele, key) => {
          if (ele.userId === item.userId) {
            // 检测是否已有该员工的记录
            index = key;
            return ele;
          }
        });
        if (isFand) {
          // 有该员工数据, 只记录员工完成数
          item.taskCompleteRatio === 0 ? arr[index].no++ : arr[index].finsh++;
        } else {
          // 没有该任务的数据就创建一个
          var obj = {
            name: item.userRealname,
            userId: item.userId,
            finsh: item.taskCompleteRatio === 0 ? 0 : 1,
            no: item.taskCompleteRatio === 0 ? 1 : 0
          };
          arr.push(obj);
        }
      });
      return arr;
    },
    // 渲染图形
    renderChart() {
      this.chartData = this.setData(this.data);
      // console.log(this.chartData, '处理好的数据')
      this.option.yAxis.data = [];
      this.option.series[0].data = [];
      this.chartData.forEach(item => {
        this.option.yAxis.data.push(item.name);
        // this.option.series[0].data.push(item.no);
        let value = (item.finsh / (item.finsh + item.no)) * 100;
        this.option.series[0].data.push(Number(value).toFixed(0));
      });
      this.myChart.setOption(this.option);
    },
    onChange(value, date) {
      this.formInline.endTime = date;
    },
    toDetail(record) {
      this.$router.push({
        name: "trainDetails",
        query: {
          userId: record.userId,
          taskId: record.taskId,
          taskPathId: record.taskPathId
        }
      });
    },
    search() {
      this.getData();
    },
    resetForm() {
      this.formInline = {};
    }
  }
};
</script>

<style lang = "scss" scoped>
.train {
  &__filter {
    &-item {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #787878;
      width: 350px;
      margin-bottom: 45px;
    }
  }
  &__line {
    width: 100%;
    height: 1px;
    background: #e4e4e4;
    margin-top: 36px;
  }
  /* &__table{
     height: 345px;
     overflow-y: scroll;
   } */
  &__charts {
    margin-top: 60px;

    &-tip {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding-left: 50px;
    }
  }
}
.text__center_btn {
  width: 94px;
  height: 38px;
  margin-right: 15px;
  border-radius: 25px;
  color: #FFFFFF;
}
#charts {
  width: 100%;
  height: 400px;
  margin-top: 20px;
}
::v-deep .ant-table-align-center {
  background: #F7F3EC;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}
::v-deep .ant-table-column-title{
  color: #C4A468;
}
</style>
